export const SOCIAL = {
  facebook: {
    url: 'https://www.facebook.com/SomosCodear',
    title: 'WebConf en Facebook'
  },
  twitter: {
    url: 'https://twitter.com/WebConfAr',
    title: 'WebConf en Twitter'
  },
  instagram: {
    url: 'https://instagram.com/WebConfAr',
    title: 'WebConf en Instagram'
  }
};
