export const MENU = [
  {
    title: 'Fotos',
    url: '/photos'
  },
  {
    title: 'La conferencia',
    url: '/about'
  },
  {
    title: 'Disertantes',
    url: '/speakers'
  },
  {
    title: 'Cronograma',
    url: '/schedule'
  },
  {
    title: 'Lugar',
    url: '/venue'
  },
  {
    title: 'Sponsors',
    url: '/sponsors'
  }
];
