// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/vercel/workpath0/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/vercel/workpath0/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("/vercel/workpath0/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-photos-jsx": () => import("/vercel/workpath0/src/pages/photos.jsx" /* webpackChunkName: "component---src-pages-photos-jsx" */),
  "component---src-pages-schedule-jsx": () => import("/vercel/workpath0/src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-speakers-jsx": () => import("/vercel/workpath0/src/pages/speakers.jsx" /* webpackChunkName: "component---src-pages-speakers-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("/vercel/workpath0/src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-venue-jsx": () => import("/vercel/workpath0/src/pages/venue.jsx" /* webpackChunkName: "component---src-pages-venue-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/workpath0/.cache/data.json")

